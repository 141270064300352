@font-face {
  font-weight: 300;
  font-family: 'Gotham';
  font-style: normal;
  src: url('../fonts/Gotham-Light_Web.52135bfa.woff2') format('woff2');
}

@font-face {
  font-weight: 400;
  font-family: 'Gotham';
  font-style: normal;
  src: url('../fonts/Gotham-Book_Web.7fa96aa0.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-family: 'Gotham';
  font-style: normal;
  src: url('../fonts/Gotham-Bold_Web.d23d96ae.woff2') format('woff2');
}

@font-face {
  font-weight: 900;
  font-family: 'Gotham';
  font-style: normal;
  src: url('../fonts/Gotham-Black_Web.a772a67e.woff2') format('woff2');
}

body {
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  background-color: #17171f;
}

.header {
  height: 70px;
  width: 100%;
  background-color: #1b1c24;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header__title {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.content__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  margin: 8px 0;
  color: #ffffff;
  background-color: #1b1c24;
}

.item__title {
  margin-left: 15px;
  font-size: 18px;
}

.item__img {
  width: 40px;
}

.content__item > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content__item > div:last-child {
  display: flex;
  flex-direction: column;
}

.item__confirm {
  display: block;
  padding: 8px 22px;
  color: #392c22;
  font-weight: 900;
  font-size: 11px;
  border: none;
  text-transform: uppercase;
  background-color: #e69d5d;
  border-radius: 7px;
  cursor: pointer;
}

.item__decline {
  margin-top: 10px;
  display: block;
  padding: 8px 22px;
  color: #392c22;
  font-weight: 900;
  font-size: 11px;
  border: none;
  text-transform: uppercase;
  background-color: #e69d5d;
  border-radius: 7px;
  cursor: pointer;
}

.code {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 54px;
  margin-bottom: 10px;
  color: #ffffff;
  background-color: #1b1c24;
}

.code__title {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.code__qr {
  margin-top: 15px;
  cursor: pointer;
}

.code__cookies {
  margin-top: 10px;
  cursor: pointer;
}

.item__input {
  height: 42px;
  background-color: #282c40;
  border-radius: 10px;
  border: none;
  padding: 20px;
  color: #ffffff;
  outline: none;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  margin-bottom: 10px;
}

.links {
  flex-direction: column;
  height: calc(100vh - 110px);
  align-items: flex-start;
  justify-content: flex-start;
  transition: height 0.3s ease-in-out;
  display: flex;
  padding: 20px 24px;
  margin: 8px 0;
  color: #ffffff;
  background-color: #1b1c24;
}

.item__textarea {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #282c40;
  border-radius: 10px;
  border: none;
  resize: none;
  padding: 20px;
  color: #ffffff;
  margin-top: 10px;
}

.item__ha1 {
  margin-bottom: 10px;
}
input[type='file'] {
  display: none;
}

.custom-file-upload {
  display: inline-lock;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #e69d5d;
  margin-bottom: 15px;
  color: #000000;
}

.skins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    background-color: #1b1c24;
    padding: 20px 24px;
    margin: 8px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    & > img {
      width: 130px;
    }

    & > span {
      font-size: 20px;
      margin-top: 5px;
    }
  }
}

.games {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 10px;

  & > img {
    width: 40px;
    height: 40px;
    margin: 0 8px;
    border-radius: 8px;
  }
}

.stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.stats__item {
  width: 100%;
  max-width: 150px;
  height: 150px;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  color: white;
  background: #1b1c24;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}

.stats__item > span {
  font-weight: bold;
  font-size: 18px;
}

.stats__item > svg {
  width: 40px;
  height: 40px;
  fill: white;
}

::-webkit-scrollbar {
  display: none;
}
